import pic from "../media/portfolio_pic.jpg";
import { Envelope, Mobile, MapPin, Cake, Id, Menu } from "../components/svg.jsx";
import { BubbleA,  BubbleB, BubbleLang, LineDown } from "../components/objects.jsx";
import { useSearchParams } from "react-router-dom";
import data from '../components/data.json';
import {useState} from 'react';
// import { Disclosure } from '@headlessui/react'
// import 'css-doodle';

function CV() {
    const [isMenuActive, setMenuActive] = useState('hidden');
    const [searchParams, setSearchParams] = useSearchParams();
    const [loadPage, setLoadPage] = useState(false);
    const [getReason, setReason] = useState('');
    const margin = "mb-5";
    const resText = "sm:text-sm md:text-lg";
    const menuToggle = (e) => {
        if(isMenuActive === 'hidden') {
            setMenuActive('flex')
        } else {
            setMenuActive('hidden')
        }
    }

    fetch(`api/?token=${searchParams.get('t')}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
    })
    .then((res) => res.json())
    .then((data) => {
        if(data.data.hasOwnProperty('token')) {
            const a = new Date();
            const b = new Date(data.data.expire);

            if(a < b && data.data.access > 0) {
                setLoadPage(true);
                fetch(`api/?token=${searchParams.get('t')}`, {
                    method: 'POST',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                      'Count': data.data.access - 1
                    }
                  })
                //   .then((res) => res.json())
                //   .then((d) => {});
            } else {
                setLoadPage(false);
                setReason('expired')
            }
        } else if(data.data.hasOwnProperty('return') && data.data.return === 'nope...') {
            setLoadPage(false);
            setReason('denied')
        }
    });

    if(loadPage) {
        return (
            <>
            {/* Page w-[210mm] h-[297mm] */}
            <div className="p-10 font-avenir bg-gradient-to-r from-rose-100 to-teal-100">
                {/* Title */}
                <div className={`grid grid-cols-1 xs:grid-cols-2 w-44 gap-3  ${margin}`}>
                    <div className="flex w-40 h-40  border border-custom-color rounded-full overflow-hidden">
                        <img className="object-cover" src={pic} alt="" />
                    </div> {/* Image */}
                    <div className="ml-0 xs:ml-20 w-80 xs:w-96">
                        <div className="text-4xl mb-2">Dalius Singer</div> {/* Name */}
                        <div className={`font-bold text-custom-color text-sm ${resText}`}>Software-Entwickler / Coder</div> {/* Title */}
                        <div className={`font-bold text-custom-color text-sm ${resText}`}>Trompeter / Musikpädagoge / Künstler</div> {/* Title */}
                        
                    </div>
                </div>

                {/* hidden menu */}
                <div className={`${isMenuActive} absolute items-center justify-center top-5 left-5 bg-custom-color bg-opacity-90 w-[calc(100%-2.5rem)] h-[calc(100%-2.5rem)] rounded-lg z-40`}>
                    <div className="sm:block space-y-3 sticky text-white font-bold text-sm">
                        {/* email */}
                        <div className="flex justify-end place-items-center"> 
                            <a className="mr-3" href="mailto: dalius.singer@email.com">dalius.singer@email.com</a>
                            <Envelope color="text-green-200"/>
                        </div>
                        {/* phone */}
                        <div className="flex justify-end place-items-center"> 
                            <div className="mr-3">+41 79 898 29 85</div>
                            <Mobile color="text-green-200"/>
                        </div>
                        {/* address */}
                        <div className="flex text-right justify-end place-items-center"> 
                            <a className="mr-3" href="https://www.google.ch/maps/place/Breitensteinstrasse+51,+8037+Z%C3%BCrich/@47.3932557,8.5177126,18.05z/data=!4m6!3m5!1s0x47900a43fb316d8f:0x73d5102cbd7f2cdf!8m2!3d47.3933749!4d8.5187867!16s%2Fg%2F11dznpc27w">Breitensteinstrasse 51 <br />8037, Zürich</a>
                            <MapPin color="text-green-200"/>
                        </div>
                        {/* birth loc */}
                        <div className="flex justify-end place-items-center"> 
                            <a className="mr-3" href="https://www.google.ch/maps/place/Jonava,+Rajongemeinde+Jonava,+Litauen/@55.0579701,24.1188273,11.07z/data=!4m15!1m8!3m7!1s0x46dd94140f33be13:0xf30a54d3a55dbab9!2sLitauen!3b1!8m2!3d55.169438!4d23.881275!16zL20vMDRnemQ!3m5!1s0x46e70922f0d9bbcb:0x400d18c70e9d930!8m2!3d55.0727242!4d24.2793337!16zL20vMDdtcXox">23 Oktober 1985, Litauen</a>
                            <Cake color="text-green-200"/>
                        </div>
                        <div className="flex justify-end place-items-center"> 
                            <div className="mr-3">Aufenthaltsbewilligung B EU/EFTA</div>
                            <Id color="text-green-200"/>
                        </div>   
                        {/* <div className="flex justify-end place-items-center">
                            <a className="mr-3" href="https://daliusinger.com/cv?token=LsAnj59606">https://daliusinger.com/cv?token=LsAnj59606</a>
                            <Link/>
                        </div>    */}
                    </div>
                </div>
        
                {/* Contacts */}
                <div className="absolute right-10 top-5 text-xs">
                    <button onClick={e => menuToggle(e)} data-collapse-toggle="contactBar" type="button" className="absolute z-50 -right-4 p-2 bg-custom-color bg-opacity-0 text-sm rounded-lg sm:hidden" aria-controls="contactBar" aria-expanded="false">
                        <Menu pos={isMenuActive}/>
                    </button>
                    <div className={`hidden sm:block space-y-3`} id="contactBar">
                        {/* email */}
                        <div className="flex justify-end place-items-center"> 
                            <a className="mr-3" href="mailto: dalius.singer@email.com">dalius.singer@email.com</a>
                            <Envelope color="text-custom-color"/>
                        </div>
                        {/* phone */}
                        <div className="flex justify-end place-items-center"> 
                            <div className="mr-3">+41 79 898 29 85</div>
                            <Mobile color="text-custom-color"/>
                        </div>
                        {/* address */}
                        <div className="flex text-right justify-end place-items-center"> 
                            <a className="mr-3" href="https://www.google.ch/maps/place/Breitensteinstrasse+51,+8037+Z%C3%BCrich/@47.3932557,8.5177126,18.05z/data=!4m6!3m5!1s0x47900a43fb316d8f:0x73d5102cbd7f2cdf!8m2!3d47.3933749!4d8.5187867!16s%2Fg%2F11dznpc27w">Breitensteinstrasse 51 <br />8037, Zürich</a>
                            <MapPin color="text-custom-color"/>
                        </div>
                        {/* birth loc */}
                        <div className="flex justify-end place-items-center"> 
                            <a className="mr-3" href="https://www.google.ch/maps/place/Jonava,+Rajongemeinde+Jonava,+Litauen/@55.0579701,24.1188273,11.07z/data=!4m15!1m8!3m7!1s0x46dd94140f33be13:0xf30a54d3a55dbab9!2sLitauen!3b1!8m2!3d55.169438!4d23.881275!16zL20vMDRnemQ!3m5!1s0x46e70922f0d9bbcb:0x400d18c70e9d930!8m2!3d55.0727242!4d24.2793337!16zL20vMDdtcXox">23 Oktober 1985, Litauen</a>
                            <Cake color="text-custom-color"/>
                        </div>
                        <div className="flex justify-end place-items-center"> 
                            <div className="mr-3">Aufenthaltsbewilligung B EU/EFTA</div>
                            <Id color="text-custom-color"/>
                        </div>   
                        {/* <div className="flex justify-end place-items-center">
                            <a className="mr-3" href="https://daliusinger.com/cv?token=LsAnj59606">https://daliusinger.com/cv?token=LsAnj59606</a>
                            <Link/>
                        </div>    */}

                    </div>
                </div>
        
                {/* About */}
                <div className={`relative ${margin} text-justify text-sm sm:text-base`}>
                Kreative und verantwortungsvolle Persönlichkeit mit über {new Date(new Date() - new Date('September 1, 2003 00:00:00')).getFullYear() - 1970} Jahren musikalischer sowie über {new Date(new Date() - new Date('September 1, 2015 00:00:00')).getFullYear() - 1970} Jahren Software-Entwicklung und Kunstforschungserfahrung. 
                Mein Interesse und Fachwissen in Kunstwerken deckt einen grossen Bereich ab, von Grundlagen bis hin zu innovativen und fortgeschrittenen Lösungen für jede Situation. 
                Ich bin leidenschaftlicher Pädagoge für Musik und Kunst, mit grosser Motivation, ein kreatives und interessantes Programm für meine Studenten in jedem Alter zu entwickeln.
                </div>

                {/* Interests - FACHGEBIETE */}
                <div className={`relative ${margin} -left-10 w-[calc(100vw-40px)] text-sm sm:text-base`}>
                    <div className="font-bold text-xl ml-10">FACHGEBIETE</div>
                    <div className="w-screen h-0.5 bg-custom-color -z-10"></div> {/* Separator line */}
                    <div className="ml-10 mt-2 flex flex-wrap text-center text-white roun gap-2">
                        {data.interests.map((x, i) => (
                            <div className="bg-custom-color w-auto rounded-md px-2 py-0.5" key={i}>{x}</div>
                        ))}
                    </div>
                </div>

                {/* Pro Experience - BERUFSERFAHRUNG */}
                <div className={`relative ${margin} -left-10 w-screen`}>
                    <div className="font-bold text-xl ml-10">BERUFSERFAHRUNG</div>
                    <div className="w-screen h-0.5 bg-custom-color -z-10"></div> {/* Separator line */}
                    {data.jobs.map((el,i) => (
                        <div className="flex mt-3" key={i}>
                            {/* Graphics */}
                            <div className="relative w-14 top-1.5 place-content-center">
                                <BubbleA/>                          
                                {i + 1 < data.jobs.length && (
                                    <LineDown/>
                                )}
                            </div>
                            {/* Generative Experience Field */}
                            <div className="relative w-[calc(100%-94px)]">
                                <div className="font-bold text-base sm:text-lg">{el.name}</div> {/* title */}
                                <a className="text-sm sm:text-lg underline" href={el.link} target="_blank" rel="noopener noreferrer">{el.place}</a> {/* place */}
                                <div className="flex w-full text-xs sm:text-base">
                                    <div className="text-custom-color italic">{el.date.from} - {el.date.until}</div> {/* date */}
                                    <div className="relative flex-1 text-right float-right text-custom-color italic">{el.location}, {el.land}</div> {/* loc */}
                                </div>
                                
                                {/* description box */}
                                <div className="relative w-full top-1 text-sm sm:text-base">
                                    <div className="absolute border-l-[3px] border-t-[3px] border-b-[3px] border-custom-color h-full w-5"></div> {/* left side */}
                                    <div className="absolute border-r-[3px] border-t-[3px] border-b-[3px] border-custom-color h-full w-5 right-0"></div> {/* right side */}
                                    <div className="relative ml-5 py-1">
                                        {el.description.map((element, index) => (
                                            <div className="flex" key={index}>
                                                <BubbleB/>
                                                <div className="pr-5">{element}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Cooperators */}
                <div className={`relative ${margin} -left-10 w-[calc(100vw-40px)] text-sm sm:text-base`}>
                    <div className="font-bold text-xl ml-10">COOPERATORS</div>
                    <div className="w-screen h-0.5 bg-custom-color -z-10"></div> {/* Separator line */}
                    <div className="ml-10 mt-2 flex flex-wrap text-center text-white roun gap-2">
                        {data.collaboratos.map((x, i) => (
                            <a className="bg-custom-color w-auto rounded-md px-2 py-0.5 hover:opacity-80 transition-opacity" key={i} href={x.link} target="_blank" rel="noopener noreferrer">{x.name}</a>
                        ))}
                    </div>
                </div>            

                {/* Artistic Experience - Künstlerische Erfahrungen */}
                <div className={`relative ${margin} -left-10 w-screen`}>
                    <div className="font-bold text-xl ml-10">KÜNSTLERISCHE ERFAHRUNGEN</div>
                    <div className="w-screen h-0.5 bg-custom-color -z-10"></div> {/* Separator line */}
                    {data.artExperience.map((el,i) => (

                        <div className="flex mt-3" key={i}>
                            {/* Graphics */}
                            <div className="relative w-14 top-1.5 place-content-center">
                                <BubbleA/>                          
                                {i + 1 < data.artExperience.length && (
                                    <LineDown/>
                                )}
                            </div>
                            
                            {/* Generative Experience Field */}
                            <div className="relative w-[calc(100%-94px)]">
                                <div className="font-bold text-base sm:text-lg">{el.pos}</div> {/* title */}
                                <a className="text-sm sm:text-lg underline" href={el.link} target="_blank" rel="noopener noreferrer">{el.where}</a> {/* place */}
                                <div className="flex w-auto text-xs sm:text-base">
                                    {/* date with condition*/}
                                    {el.date.map((ele, inx) => {
                                        if(!ele.until) {
                                            if(ele.link) {
                                                return el.date.length <= 1 ? <a className="text-custom-color italic text-left float-left" key={inx} href={ele.link} target="_blank" rel="noopener noreferrer">{ele.from}</a> : (inx + 1) !== el.date.length ? <a className="text-custom-color italic text-left float-left" key={inx} href={ele.link} target="_blank" rel="noopener noreferrer">{ele.from}&nbsp;|&nbsp;</a> : <a className="text-custom-color italic text-left float-left" key={inx} href={ele.link} target="_blank" rel="noopener noreferrer">{ele.from}</a>
                                            } else {
                                                return el.date.length <= 1 ? <div className="text-custom-color italic text-left float-left" key={inx}>{ele.from}</div> : (inx + 1) !== el.date.length ? <div className="text-custom-color italic text-left float-left" key={inx}>{ele.from} |&nbsp;</div> : <div className="text-custom-color italic text-left float-left" key={inx}>{ele.from}</div>
                                            }
                                        } else {
                                            if(ele.link) {
                                                return <a className="text-custom-color italic" key={inx} href={ele.link} target="_blank" rel="noopener noreferrer">{ele.from} - {ele.until}</a>
                                            } else {
                                                return <div className="text-custom-color italic" key={inx}>{ele.from} - {ele.until}</div>
                                            }
                                            
                                        }
                                    })}                                
                                    <div className="relative flex-1 text-right float-right text-custom-color italic">{el.location}, {el.land}</div> {/* loc */}
                                </div>
                                
                                {/* description box */}
                                <div className="relative w-full top-1 text-sm sm:text-base">
                                    <div className="absolute border-l-[3px] border-t-[3px] border-b-[3px] border-custom-color h-full w-5"></div> {/* left side */}
                                    <div className="absolute border-r-[3px] border-t-[3px] border-b-[3px] border-custom-color h-full w-5 right-0"></div> {/* right side */}
                                    <div className="relative ml-5 py-1">
                                        {el.description.map((element, index) => (
                                            <div className="flex" key={index}>
                                                <BubbleB/>
                                                <div className="pr-5">{element}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Education - AUSBILDUNG */}
                <div className={`relative ${margin} -left-10 w-screen`}>
                    <div className="font-bold text-xl ml-10">AUSBILDUNG</div>
                    <div className="w-screen h-0.5 bg-custom-color -z-10"></div> {/* Separator line */}
                    {data.education.map((el,i) => (
                        <div className="flex mt-3" key={i}>
                            {/* Graphics */}
                            <div className="relative w-14 top-1.5 place-content-center">
                                <BubbleA/>                          
                                {i + 1 < data.education.length && (
                                    <LineDown/>
                                )}
                            </div>
                            {/* Generative Experience Field */}
                            <div className="relative w-[calc(100%-94px)]">
                                <div className="font-bold text-base sm:text-lg">{el.degree}</div> {/* title */}
                                <a className="text-sm sm:text-lg underline" href={el.link} target="_blank" rel="noopener noreferrer">{el.school}</a> {/* place */}
                                <div className="flex w-full text-xs sm:text-base">
                                    {/* date with condition*/}
                                    {!el.date.until && (
                                        <div className="text-custom-color italic">{el.date.from}</div>
                                    )}
                                    {el.date.until && (
                                        <div className="text-custom-color italic">{el.date.from} - {el.date.until}</div>
                                    )}
                                    
                                    <div className="absolute right-0 text-custom-color italic">{el.location}, {el.land}</div> {/* loc */}
                                </div>
                                
                                {/* description box */}
                                {el.thesis.length > 0 && (
                                    <div className="relative w-full top-1 text-sm sm:text-base">
                                        <div className="absolute border-l-[3px] border-t-[3px] border-b-[3px] border-custom-color h-full w-5"></div> {/* left side */}
                                        <div className="absolute border-r-[3px] border-t-[3px] border-b-[3px] border-custom-color h-full w-5 right-0"></div> {/* right side */}
                                        <div className="relative ml-5 py-1">
                                            {el.thesis.map((element, index) => (
                                                <div className="flex" key={index}>
                                                    <BubbleB/>
                                                    <div className="pr-5">{element}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                )}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Languages - SPRACHEN */}
                <div className={`relative ${margin} -left-10 w-screen`}>
                    <div className="font-bold text-xl ml-10">SPRACHEN</div>
                    <div className="w-screen h-0.5 bg-custom-color -z-10"></div> {/* Separator line */}
                    <div className='grid grid-cols-2 mt-3' style={{gridTemplateColumns: `repeat(${data.languages.length}, minmax(0, 1fr))`}}>
                        {data.languages.map((el,i) => (
                            <BubbleLang name={el.language} size={el.size} desc={el.description} key={i}/>
                        ))}
                    </div>
                </div>

                {/* Graphics */}
                {/* <div className="absolute top-3 left-0 w-10 h-auto object-fill -z-10">
                    <TopGraphics/>
                </div> */}
            </div>
            </>
        )
    } else {
        if(getReason === 'expired') {
            return (
                <div className="absolute text-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-color-text text-base sm:text-xl">
                    <div>It looks like, that this link is expired...</div>
                    <div>Please contact me on <a className="text-emerald-600 underline" href="mailto: mail@daliusinger.com">mail@daliusinger.com</a> to get more time!</div>
                    <div className="mb-5">Thanks</div>
                    <a className="text-color-text text-xl bg-emerald-900 px-3 py-2 rounded-lg hover:bg-emerald-700 transition ease-in-out duration-200" href="https://daliusinger.com">Home</a>
                </div>
            )
        } else if(getReason ==='denied') {
            return (
                <div className="absolute text-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-color-text text-base sm:text-xl">
                    <div>It looks like, something went wrong...</div>
                    <div></div>
                    <div>If you got here from my CV and getting this error please contact me on <a className="text-emerald-600 underline" href="mailto: mail@daliusinger.com">mail@daliusinger.com</a> to resolve this issue</div>
                    <div>Please make sure that link is original was not edited.</div>
                    <div className="mb-5">Thanks</div>
                    <a className="text-color-text text-xl bg-emerald-900 px-3 py-2 rounded-lg hover:bg-emerald-700 transition ease-in-out duration-200" href="https://daliusinger.com">Home</a>
                </div>
            )
        } else {
            return (
                <div className="absolute text-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-color-text text-base sm:text-xl">
                    <div role="status">
                        <svg aria-hidden="true" className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-700 fill-emerald-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                    {/* <a className="text-color-text text-xl bg-emerald-900 px-3 py-2 rounded-lg hover:bg-emerald-700 transition ease-in-out duration-200" href="https://daliusinger.com">Home</a> */}
                </div>
            )
        }
    }
  }
  
  export default CV;