function Index() {
    return (
      <div className="absolute text-center font-montserrat w-full top-1/2 -translate-y-1/2">
        <h1 className="text-5xl font-bold text-title-color pb-8">DALIUS SINGER</h1>
        <div className="text-color-text text-base sm:text-xl mb-8">
          <div>ARTS</div>
          <div>MUSIC</div>
          <div>AUDIO</div>
          <div>CODING</div>
          <div>TRUMPET</div>
          <div>RESEARCH</div>
          <div>EDUCATION</div>
          <div>WEB DESIGN</div>
          <div>SOFTWARE DEVELOPMENT</div>
          <div>RÉALISATEUR EN INFORMATIQUE MUSICALE</div>
        </div>
        <a className="text-color-text text-xl bg-emerald-900 px-3 py-2 rounded-lg hover:bg-emerald-700 transition ease-in-out duration-200" href="mailto: mail@daliusinger.com">CONTACT</a>
      </div>
    );
  }
  
  export default Index;