import { useState } from 'react';

export const BubbleA = () => {
    return(
        <div className="absolute left-1/2 -translate-x-1/2 rounded-full w-4 h-4 border-[3px] border-custom-color"></div>
    )
}

export const BubbleB = () => {
    return(
        <span className="relative right-[7px] text-custom-color">•</span>
    )
}

export const BubbleLang = ({name, size, desc}) => {
    const [hover, setHover] = useState(false);
    const handleMouseEnter = () => {
        setHover(true);
    };
    
    const handleMouseLeave = () => {
        setHover(false);
    };
    return(
        <div className="font-semibold">
            <span className={`absolute flex items-center justify-center left-9 -top-[3.5rem] h-20 w-[calc(100vw-4.5rem)] transition-opacity bg-custom-color px-2 text-sm text-gray-100 rounded-lg text-center`} style={{opacity: hover ? 0.9 : 0}}>{desc}</span>
            <div className="relative left-1/2 -translate-x-1/2 flex  justify-center w-10 h-10 border-2 border-custom-color rounded-full">
                <div className='absolute w-full h-full rounded-full z-10 hover:cursor-none' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}></div>
                <div className={`relative top-1/2 -translate-y-1/2 border-2 bg-custom-color rounded-full border-none`} style={{ width: 40 * size / 100, height: 40 * size / 100}}></div>
                
                {/* <span class="group-hover:opacity-100 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto">Tooltip</span> */}
            </div>
            <div className="text-center">{name}</div>
        </div>
    )
}

export const LineDown = () => {
    return(
        <div className="absolute left-1/2 -translate-x-1/2 top-3.5 border-[0.10rem] h-full border-custom-color z-10"></div> 
    )
}